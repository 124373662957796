<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#00F"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="d-none d-sm-table-cell">用户昵称</b-th>
          <b-th class="d-none d-sm-table-cell">缴费日期</b-th>
          <b-th class="d-none d-sm-table-cell" v-b-tooltip.hover title="点击切换启动/结束时间" @click="extmflag">{{tmflag?'结束时间':'启动时间'}}</b-th>
          <b-th class="d-none d-sm-table-cell">账单</b-th>
          <b-th>实付</b-th>
          <b-th v-b-tooltip.hover title="编号1表示1号端口,12表示12号端口">电桩#端口</b-th>
          <b-th v-b-tooltip.hover title="完成充电由服务器决定(0.15),充电完成由设备判定(0.04~0.08)">断电原因</b-th>
          <b-th v-b-tooltip.hover title="断电时按分钟计费">计划,实际,功率</b-th>
        </b-tr>        
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td class="d-none d-sm-table-cell">{{item.nickname}}</b-td>
          <b-td class="d-none d-sm-table-cell" v-b-tooltip.hover :title="item.paytime">{{item.paydate}}</b-td>
          <b-td class="d-none d-sm-table-cell" v-b-tooltip.hover :title="item.startimeday">{{tmflag?item.paytime:item.startimestr}}</b-td>
          <b-td class="d-none d-sm-table-cell">{{item.billmoney}}</b-td>
          <b-td>{{item.paymoney}}</b-td>
          <b-td>{{item.chargerid}}#{{item.portid}}</b-td>
          <b-td>{{reasons[item.reason]}}</b-td>
          <b-td>{{item.plan}}时,{{item.fact}}分,{{item.avgpow}}瓦</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="lightning"></b-icon> 电动单车充电记录</h4>
        </template>
        <b-card-text>
          <p>
            没有发现满足条件的数据<br/><br/>
            扫码-绑手机-充值-充电<br/>
            充电结束，断电自动扣费<br/>
            <b-form inline class="mt-4"><b-alert :show="admnodft">{{$t('message.cfmdefault')}}</b-alert></b-form>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-input-group class="mr-4 mb-4">
        <b-form-input :placeholder="$t('message.entermobile')" v-model="searchmobile" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-button-group class="mb-4 d-none d-lg-block" style="width:580px;">
          <b-input-group>
              <b-form-datepicker placeholder="起始日期(包含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-start" v-model="datstart"></b-form-datepicker>
              <b-form-datepicker placeholder="结束日期(不含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-end"  v-model="datend"></b-form-datepicker>
              <b-input-group-append>
                  <b-button variant="info" @click="download" :disabled="clicked">
                      <span v-if="clicked"><b-icon variant="danger" icon="circle-fill" animation="throb" scale="4" font-scale="1" class="mr-2"></b-icon>下载</span>
                      <span v-else><b-icon icon="cloud-download" scale="1" font-scale="1" class="mr-2"></b-icon>下载</span>
                  </b-button>
              </b-input-group-append>
          </b-input-group>
      </b-button-group>
    </b-form>
    <div class="pl-5 pr-5 mt-5 mb-5 d-none d-lg-block">
      <b-card class="xnshadow">
        <b-card-text>
          小屏幕手机显示内容会减少,手机屏幕机向显示内容会变化,iPhone手机可缩小字体,可实现电脑屏幕的显示内容<br/>
          数据以启动时间排序,点击启动时间可切换至结束时间,电脑模式下鼠标移到缴费日期的数字上可显示结束时间<br/>
          断电原因主要有：未接设备,到时停充,插头拔出,功率极限,余额不足,主动停止,温度太高,完成充电,充电完成<br />
          "完成充电"是由服务器指标决定(用户管理-断电电流),"充电完成"由设备内部指标决定(0.04~0.08A)<br/>
          充电器的充电电流都是先大后小,充电过程前半部分功率较高,然后慢慢减小,充电时间久一点就有可能让平均功率拉低至价格小的一档<br/>
          充电功率极限一般会在一分钟左右得出结论,所以大功率充电会有一分钟左右的扣费<br/>
          免费客户账单金额为实际充电消费,实付金额为0<br/>
          如果某客户即有赠送金额,又有优惠券,那么,该客户账单的账单金额首先扣除优惠券,剩下的为实付,实付金额中先扣个人充值,个人充值不够再扣赠送<br/>
          本系统不支持直接跳转至某页,只能一页一页发翻,支持手机号查询
        </b-card-text>
      </b-card>
    </div>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  import { QRSITE } from '@/config'
  export default {
    name: 'chargebk',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        admnodft:false,
        searchmobile:'',
        reasons: ['系统停充','未接设备','到时停充','插头拔出','功率极限','余额不足','线损过高','完成充电','温度太高','主动停止','充电完成'],
        items: [],
        pageid:0,
        tmflag:0,
        datstart:'',
        datend:'',
        clicked:false,
        timeoutcnts:0,
        genrated:false,
        newpagestart:[]
      };
    },
    methods: {
      search() {
        let mo = parseInt(this.searchmobile);
        if (this.searchmobile.length > 0 && isNaN(mo)) {
          this.modalmsg = '无效的手机号，请输入数字！';
          this.modalshow = true;
        } else if (this.searchmobile.length > 0 && this.searchmobile.length < 4) {
          this.modalmsg = '无效的手机号，最少4位数！';
          this.modalshow = true;
        } else if (this.searchmobile.length > 11) {
          this.modalmsg = '手机号输入有误，最多11位！';
          this.modalshow = true;
        } else {
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
        }
      },
      extmflag() {
        this.tmflag = 1-this.tmflag;
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      fetchData() {
        this.isloading = true;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let token = localStorage.getItem('token');
        let theapi = '/chargebk?tm='+new Date().getTime()+'&token='+token+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h;
        if (this.searchmobile) {
          theapi = theapi + '&mobile='+this.searchmobile
        } else if (this.$route.query.openid) {
          theapi = theapi + '&openid='+this.$route.query.openid;
        } else if (this.$route.query.siteid) {
          theapi = theapi + '&siteid='+this.$route.query.siteid;
        } else if (this.$route.query.chargerid) {
          theapi = theapi + '&chargerid='+this.$route.query.chargerid;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        this.axios.get(theapi).then(axresp => {
          this.isloading = false;
          if (axresp.status==200) {
            let axdata = axresp.data;
            this.items = axdata.Items;
            if (axdata.LastEvaluatedKey) {
              if (this.newpagestart.length<=this.pageid) {
                this.newpagestart.push(axdata.LastEvaluatedKey);
              } else {
                this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
              }
            }
          } else {
            this.items = [];
          }
        });
      },
      async download(){
          if (this.datend=='') this.datend = new Date().format('yyyy-MM-dd');
          if (this.datstart=='') {
            this.modalmsg = '开始日期不能为空；<br/>不填结束日期表示截止今日0点；<br/>日期跨度不能大于31天；<br/>查询耗时较长，请耐心等待；';
            this.modalshow = true;
          } else {
            let startDate = new Date(Date.parse(this.datstart.replace(/-/g,'/')));
            let endDate = new Date(Date.parse(this.datend.replace(/-/g,'/')));
            let diff = (endDate.getTime()-startDate.getTime())/86400000;
            if (diff<0) {
                this.modalmsg = '开始日期不能晚于结束日期';
                this.modalshow = true;
            } else if (diff>31){
                this.modalmsg = '下载日期跨度不能超过31天';
                this.modalshow = true;
            } else {
                this.clicked = true;
                let token = localStorage.getItem('token');
                let nowdate = new Date().format('yyyy-MM-dd');
                let resulturl = QRSITE+'/qr/excel8/'+token+'_'+this.datstart+'_'+this.datend+'_'+nowdate+'.xlsx';
                let hdresp = undefined;
                try{
                    hdresp = await this.axios.head(resulturl);
                }catch(e){
                    if (e) hdresp = undefined;
                }
                if (hdresp && hdresp.status==200) {
                    this.clicked = false;
                    this.genrated = false;
                    window.location.href = resulturl;
                } else {
                    if (this.genrated==false) {
                        let today = new Date().format('yyyy-MM-dd');
                        let downdone = localStorage.getItem('downdone8');
                        if (downdone && downdone==today) {
                            this.modalmsg = '该功能每天限制运行一次，你已经运行过了，请明天再来。';
                            this.modalshow = true;
                            this.clicked = false;
                        } else {
                            var msg = '统计下载每天限运行一次，本次运行后今天将无法再次启动，建议起止日期设置为每月1日，你真的要现在运行吗？';
                            if (confirm(msg)) {
                                localStorage.setItem('downdone8', today);
                                this.genrated = true;
                                let theapi = '/chargedown?tm='+new Date().getTime()+'&token='+token+'&datstart='+this.datstart+'&datend='+this.datend;
                                this.axios.get(theapi).then(() => {});
                                this.modalmsg = '查询一个月需要10分钟或更长时间；<br/>本功能每日限制执行一次；<br/>建议每次查询1日到下月1日；<br/>查询时间为选择日的0点，所以不含结束当天；<br/>查询的时间间隔最大为31天；<br/>本查询比较费时，请耐心等待；<br/>请不要频繁反复使用本功能；<br/>下载后请妥善保存文件；<br/>如果长时间没有结果，请刷新页面重新查询；<br/>结果返回前光标会一直闪；';
                                this.modalshow = true;
                            } else {
                                this.clicked = false;
                            }
                        }
                    }
                    if (this.clicked) {
                        this.timeoutcnts++
                        if (this.timeoutcnts<3) {
                            setTimeout(this.download, 10000);
                        } else {
                            this.clicked = false;
                            this.modalmsg = '后台正在进行数据统计汇总<br/>10分钟后选择相同的日期下载数据';
                            this.modalshow = true;
                        }
                    }
                }
            }
          }
      },
    }
  }
</script>
